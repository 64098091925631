import { h } from "preact"
import render from "preact-render-to-string"
import { Router } from "preact-router"
import { Provider } from "@preact/prerender-data-provider"

import { setup } from "goober"

import theme, { GlobalStyles }  from "./utils/theme"

import Layout from "./components/Layout"

import Home from "./routes/Home"
import Instructions from "./routes/Instructions"

setup(h, undefined, () => theme)

export const App = (props) => {
  return (
    <Provider value={props}>
      <Layout>
        <GlobalStyles />
        <Router>
          <Home path="/" lang="en-us" />
          <Home path="/:lang" />
          <Instructions path="/instructions/" lang="en-us" />
          <Instructions path="/:lang/instructions/" />
        </Router>
      </Layout>
    </Provider>
  )
}

render(App)

export default App
